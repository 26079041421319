import React from 'react';
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import * as styles from './contact-content.module.scss';
import RichText from "../../common/rich-text";
import { v4 as uuidv4 } from 'uuid';

const ContactContent = ({ slice }) => {
  const mainData = slice.main;
  const asideData = slice.aside;

  const { width } = useWindowSize();

  return (
    <>
      <div className={styles.headerContainer}>
        <h1>Contact</h1>
        <div className={styles.asideWrapper} />
      </div>
      <div className={styles.container}>
        <div className={styles.mainWrapper}>
          {mainData.map((main, i) =>
            <div key={`main-${ uuidv4() }`}>
              <div className={styles.heading}>
                <RichText>{main.heading.text}</RichText>
              </div>
              <div className={styles.content}>
                <RichText>
                  {main.content.html}
                </RichText>
              </div>
            </div>
          )}
          {width <= 768 && (
            <div className={styles.asideWrapper}>
              <div className={styles.heading}>
                <RichText>{asideData.heading.text}</RichText>
              </div>
              {asideData.content.map((aside, i) =>
                <div className={styles.content} key={`aside-${ uuidv4() }`}>
                  <RichText>
                    {aside.html}
                  </RichText>
                </div>
              )}
            </div>
          )}
        </div>
        {width > 768 && (
          <div className={styles.asideWrapper}>
            <div className={styles.heading}>
              <RichText>{asideData.heading.text}</RichText>
            </div>
            {asideData.content.map((aside, i) =>
              <div className={styles.content} key={`aside-${ uuidv4() }`}>
                <RichText>
                  {aside.html}
                </RichText>
              </div>
            )}
        </div>  
        )}
      </div>
    </>
  )
};

export default ContactContent;