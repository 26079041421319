import React, {useState} from 'react';
import * as styles from './contact-form.module.scss';
import * as inputClasses from '../elements/form-elements.module.scss';
import queryString from "query-string";
import * as Yup from "yup";
import { Widget } from '@typeform/embed-react';
import { useFormik } from "formik";
import FormConfirmation from "../../../form-confirmation";
import PhoneInput from "react-phone-input-2";
import Input from "../elements/input";
import Select from "../elements/select";
import TextArea from "../elements/textarea";

const validationSchema = Yup.object().shape({
  company: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .required('Required')
    .email('Invalid email address'),
  telephone: Yup.string()
    .min(6, "Required")
    .required('Required'),
  enquiryType: Yup.string()
    .required('Required'),
  make: Yup.string(),
  model: Yup.string(),
  notes: Yup.string(),
});

const ContactForm = ({ location }) => {
  const [ isFormSubmitted, setIsFormSubmitted ] = useState(false);

  const queryStringObject = location.search ? queryString.parse(location.search) : {};

  const form = useFormik({
    initialValues: {
      company: '',
      firstName: '',
      lastName: '',
      email: '',
      telephone: '',
      centre: queryStringObject.centre ? queryStringObject.centre : '',
      enquiryType: '',
      make: '',
      model: '',
      notes: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      let formData = new FormData();
      for (let k in values) {
        formData.append(k, values[k]);
      }

      fetch("https://forms.claritycoat.com/request_call.php",
        {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          body: formData
        })
        .then(response => {
          setSubmitting(false);

          if (response.status !== 200) {
            setErrors("Server error");
            return;
          }

          setIsFormSubmitted(true);
        });
    },
  });

  return (
    <div className={styles.wrapper}>
      {/*<form onSubmit={form.handleSubmit}>*/}
      {/*  <h1>Enquiry Form</h1>*/}
      {/*  <p>*/}
      {/*    Please fill in your details below so that we can give you a call back.<br/>*/}
      {/*    <br/>*/}
      {/*    We will call you back within 48hrs between working hours Monday to Friday.*/}
      {/*  </p>*/}
      {/*  {!isFormSubmitted ? (*/}
      {/*    <div>*/}
      {/*      <div className={styles.formContainer}>*/}
      {/*        <div className={styles.leftSection}>*/}
      {/*          <Input*/}
      {/*            label="Company"*/}
      {/*            name="company"*/}
      {/*            type="text"*/}
      {/*            placeholder="your company"*/}
      {/*            onChange={form.handleChange}*/}
      {/*            value={form.values.company}*/}
      {/*            error={(form.errors.company && form.touched.company) ? form.errors.company : null}*/}
      {/*          />*/}
      {/*          <Input*/}
      {/*            label="First Name"*/}
      {/*            name="firstName"*/}
      {/*            type="text"*/}
      {/*            placeholder="Joe"*/}
      {/*            onChange={form.handleChange}*/}
      {/*            value={form.values.firstName}*/}
      {/*            error={(form.errors.firstName && form.touched.firstName) ? form.errors.firstName : null}*/}
      {/*          />*/}
      {/*          <Input*/}
      {/*            label="Last Name"*/}
      {/*            name="lastName"*/}
      {/*            type="text"*/}
      {/*            placeholder="Bloggs"*/}
      {/*            value={form.values.lastName}*/}
      {/*            onChange={form.handleChange}*/}
      {/*            error={(form.errors.lastName && form.touched.lastName) ? form.errors.lastName : null}*/}
      {/*          />*/}
      {/*          <Input*/}
      {/*            label="Email"*/}
      {/*            name="email"*/}
      {/*            type="email"*/}
      {/*            placeholder="name@company.com"*/}
      {/*            value={form.values.email}*/}
      {/*            onChange={form.handleChange}*/}
      {/*            error={(form.errors.email && form.touched.email) ? form.errors.email : null}*/}
      {/*          />*/}
      {/*          <div className={`${inputClasses.container}`}>*/}
      {/*            <label htmlFor="telephone">Telephone</label>*/}
      {/*            <div className={`${inputClasses.inputWrapper} phone-input-wrapper`}>*/}
      {/*              <PhoneInput*/}
      {/*                country={'us'}*/}
      {/*                value={form.values.telephone}*/}
      {/*                onChange={(value, country, e, formattedValue) => form.setFieldValue("telephone", formattedValue)}*/}
      {/*                enableSearch={true}*/}
      {/*                disableSearchIcon={true}*/}
      {/*                inputProps={{*/}
      {/*                  name: 'telephone',*/}
      {/*                  id: 'telephone',*/}
      {/*                  required: false,*/}
      {/*                }}*/}
      {/*                inputClass={`${inputClasses.input} ${styles.phoneInput}*/}
      {/*                  ${(form.errors.telephone && form.touched.telephone) ? inputClasses.inputError : ''}`}*/}
      {/*              />*/}
      {/*              {form.errors.telephone && form.touched.telephone && (*/}
      {/*                <div className={inputClasses.error}>{form.errors.telephone}</div>)*/}
      {/*              }*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <Select*/}
      {/*            label="Type"*/}
      {/*            name="enquiryType"*/}
      {/*            onChange={form.handleChange}*/}
      {/*            options={[*/}
      {/*              {value: '', label: '--'},*/}
      {/*              {value: 'Colour Change', label: 'Colour Change'},*/}
      {/*              {value: 'Clear Protection', label: 'Clear Protection'},*/}
      {/*              {value: 'Bespoke', label: 'Bespoke'},*/}
      {/*              {value: 'General Enquiry', label: 'General Enquiry'},*/}
      {/*            ]}*/}
      {/*            error={(form.errors.enquiryType && form.touched.enquiryType) ? form.errors.enquiryType : null}*/}
      {/*          />*/}
      {/*          <Input*/}
      {/*            label="Make"*/}
      {/*            name="make"*/}
      {/*            type="text"*/}
      {/*            placeholder="Porsche"*/}
      {/*            value={form.values.make}*/}
      {/*            onChange={form.handleChange}*/}
      {/*            error={(form.errors.make && form.touched.make) ? form.errors.make : null}*/}
      {/*          />*/}
      {/*          <Input*/}
      {/*            label="Model"*/}
      {/*            name="model"*/}
      {/*            type="text"*/}
      {/*            placeholder="911 GT3 RS"*/}
      {/*            value={form.values.model}*/}
      {/*            onChange={form.handleChange}*/}
      {/*            error={(form.errors.model && form.touched.model) ? form.errors.model : null}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*        <div className={styles.rightSection}>*/}
      {/*          <TextArea*/}
      {/*            label="Notes"*/}
      {/*            name="notes"*/}
      {/*            placeholder="Enter further information"*/}
      {/*            value={form.values.notes}*/}
      {/*            onChange={form.handleChange}*/}
      {/*            error={(form.errors.notes && form.touched.notes) ? form.errors.notes : null}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div>*/}
      {/*        <button*/}
      {/*          type="submit"*/}
      {/*          className={styles.submit}*/}
      {/*          disabled={form.isSubmitting}*/}
      {/*        >*/}
      {/*          Submit*/}
      {/*        </button>*/}
      {/*        {form.isSubmitting && <div className="audi-web__book-test-drive__preloader">Loading...</div>}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ) : (*/}
      {/*    <FormConfirmation />*/}
      {/*  )}*/}
      {/*</form>*/}
      <Widget id="dbDuKkm7" style={{ width: '100%', height: '500px' }} className="my-form" />
    </div>
  )
};

export default ContactForm;