import React from "react";
import Layout from "../components/full-page/layout";
import ContactForm from "../components/common/forms/contact-form";
import {ColorThemeContextProvider} from "../contexts/colorThemeContext";
import ContactContent from "../components/full-page/contact-content";
import CCLogoGray from "../images/cccluegrey.svg";
import {graphql} from "gatsby";


const contactData = [
  {
    main:[ 
      {
        heading: { text: "E-mail" },
        content: { html: "Send us an enquiry to  <a href='mailto:info@claritycoat.com'>info@claritycoat.com</a>" },
      },
      {
        heading: { text: "U.S. Enquiries" },
        content: { html: "please call  <a href='tel:+16052777577'>+1 605 277 7577</a>" },
      },
      {
        heading: { text: "Rest of the World" },
        content: { html: "please call  <a href='tel:++442039849496'>+44 203 984 9496</a>" },
      },
      {
        heading: { text: "WhatsApp" },
        content: { html: "Please click here to start a  <a href='https://wa.link/xcim0a'>chat</a>" },
      },

    ],
    aside: {
      heading: { text: "" },
      content: [
        { html: "  <a href='https://google.com'></a>" },
        { html: "  <a href='https://google.com'></a>" },
        { html: "  <a href='https://google.com'></a>" },
      ],
    }
  },
]

const ContactPage = ({ location, data, pageContext: { lang } }) => {
  if (!data) {
    return null;
  }

  return (
    <ColorThemeContextProvider>
      <Layout logo={CCLogoGray}
              topNav={data.prismicHome?.data.menu_links}
              footerNav={data.prismicHome?.data.footer_links}
              blogListing={data.allPrismicBlogPost?.nodes}
              currentLanguage={lang}
      >
        <ContactContent slice={contactData[0]}/>
        <div className="page-container contact-page">
          <ContactForm location={location}  />
        </div>
      </Layout>
    </ColorThemeContextProvider>
  )
};

export const query = graphql`
query ContactQuery($lang: String) {
  prismicHome(lang: {eq: $lang}) {
    data {
      menu_links {
          menu_link {
              url
              type
          }
          name {
            text
          }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`

export default ContactPage;